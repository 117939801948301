import React from 'react';
import './styles.scss';

export default function Lign () {
    return (
        <div className='lign'>
            
        </div>
    );
};
